import React from "react";
import { LABEL } from "./constants";

const InputLabelLight = ({ label }) => {
    return (
        <div>
            <p className={LABEL}>{label}</p>
        </div>
    );
};

export default InputLabelLight;
