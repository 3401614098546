export const INPUT_CONTAINER = "text-sm";
export const INPUT_FIELD_WITH_BUTTON_CLASS =
    "bg-transparent focus:outline-none w-[100%] h-[41px] px-4 opacity-50";
export const INPUT_FIELD_WITH_BUTTON_CONTAINER =
    "flex flex-row border border-Primary_Default ";
export const PROMO_SUCCESS_ALT = "promo-success";

export const ADDRESS_FIELD = "address_components";
export const GEOMETRY_FIELD = "geometry";
export const NAME_FIELD = "name";
export const UNDEFINED = "undefined";
export const PLACE_CHANGED = "place_changed";
export const ENTER_KEY = "Enter";
export const AE = "ae";
export const SA = "sa";

export const GOOGLE_PLACE_FORMATED_ADDRESS = (place) => {
    let address = {
        city: "",
        country: "",
        country_code: "",
        area: "",
        postcode: "",
        street_number: "",
        building: "",
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
    };

    for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address.street_number = `${address.street_number}, ${component.short_name}`;
                break;
            }
            case "route": {
                address.street_number = `${address.street_number}, ${component.short_name}`;
                break;
            }
            case "sublocality_level_1": {
                address.area = `${address.area}, ${component.short_name}`;
                break;
            }
            case "sublocality_level_2": {
                address.area = `${address.area}, ${component.short_name}`;
                break;
            }
            case "sublocality_level_3": {
                address.area = `${address.area}, ${component.short_name}`;
                break;
            }
            case "postal_code": {
                address.postcode = component.long_name;
                break;
            }

            case "postal_code_suffix": {
                address.postcode = `${postcode}-${component.long_name}`;
                break;
            }
            case "locality":
                address.city = component.long_name;
                break;
            case "country":
                address.country = component.long_name;
                address.country_code = component.short_name;
                break;
        }
    }

    address.area = address.area.replace(", ", "");
    address.street_number = address.street_number.replace(", ", "");
    address.building = place.name;

    return address;
};
