import React from "react";
import Select from "react-select";
import {
    INPUT_FIELD_CLASS,
    INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { Controller } from "react-hook-form";
import { NewDropdownArrowIcon } from "../../SVGs";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { AREA_FIELD } from "../../../Molecules/AddressDetailsForm/constants";

const FloatingLabelSelect = ({
    errors,
    field,
    register = () => {},
    height = "",
    style = "",
    disabled,
    defaultValue,
    options,
    name,
    value: val,
    label,
    control = ([]) => {},
    rules,
    isClearable,
    isSearchable,
    placeholder,
    setCustomerData,
    customerData,
    setValue,
}) => {
    const DropdownIndicator = () => {
        return (
            <div className="mr-2">
                <NewDropdownArrowIcon />
            </div>
        );
    };
    const getCustomStyles = (error) => {
        return {
            option: (provided, state) => {
                return {
                    ...provided,
                    cursor: "pointer",
                    color: state.isFocused ? "white" : "#0A0D14",
                    background: state.isFocused ? "#262626" : "#fff",
                    fontWeigfirstht: state.isFocused ? "500" : "normal",
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    backgroundColor: "#fff",
                    zIndex: 30,
                };
            },

            singleValue: (provided) => {
                return {
                    ...provided,
                    color: "#0A0D14",
                };
            },

            placeholder: (provided) => {
                return {
                    ...provided,
                    color: "grey",
                };
            },
            control: (provided, state) => {
                return {
                    ...provided,
                    borderRadius: 4,
                    background: "transparent !important",
                    backgroundColor: "transparent !important",
                    border: "1px solid #262626 !important",
                    width: "100%",
                    height: "48px",
                    cursor: "pointer",
                    boxShadow: "none",
                };
            },
        };
    };
    return (
        <div className="flex-1">
            <div className={`relative ${height} ${style}`}>
                <Controller
                    render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                    }) => (
                        <Select
                            styles={getCustomStyles(error?.message)}
                            components={{
                                DropdownIndicator: DropdownIndicator,
                                IndicatorSeparator: null,
                            }}
                            isSearchable={isSearchable}
                            value={value}
                            placeholder={placeholder}
                            options={options}
                            onChange={(e) => {
                                onChange(e);
                                if (!!customerData) {
                                    setValue(AREA_FIELD, null);
                                    setCustomerData({
                                        ...customerData,
                                        selectedCity: e,
                                    });
                                }
                            }}
                            onBlur={onBlur}
                            isClearable={isClearable}
                        />
                    )}
                    name={name}
                    defaultValue={val}
                    control={control}
                />
                <label
                    htmlFor={field}
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                    {label}
                </label>
            </div>
            <ErrorTextWithIcon text={errors?.[field]?.message} />
        </div>
    );
};

export default FloatingLabelSelect;
