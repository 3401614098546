import React from "react";

import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { InputLabelLight } from "../../Typography/InputLabel";

const PhoneField = ({
    errors,
    label,
    field,
    setValue,
    setError,
    clearErrors,
    watch,
    defaultValue,
    defaultCountry,
}) => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone) => {
        try {
            phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
            clearErrors(field);
        } catch (error) {
            setError(field, {
                type: "error",
                message: "Phone Number is required",
            });
        }
    };

    return (
        <div className="flex-1">
            <InputLabelLight label={label} />

            <div className="flex items-center w-full px-2.5 pb-[2px] pt-1  text-gray-900 bg-transparent rounded border border-gray-300 dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer">
                <PhoneInput
                    defaultCountry={defaultCountry}
                    value={watch(field)}
                    onChange={(phone) => setValue(field, phone)}
                    onBlur={() => isPhoneValid(watch(field))}
                />
            </div>

            <ErrorTextWithIcon text={errors?.[field]?.message} />
        </div>
    );
};

export default PhoneField;
