import React from "react";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const PromoFloatingLabelInputField = ({
    errors,
    label,
    type,
    placeholder,
    field,
    register,
    height = "",
    style = "",
    disabled,
    icon,
    defaultValue = "",
}) => {
    return (
        <div className="flex-1">
            <div className={`relative`}>
                <input
                    type={type}
                    id={field}
                    name={field}
                    {...register(field)}
                    className="block px-2.5 pb-2.5 pt-2 w-full text-base text-gray-900 bg-transparent rounded border border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                    placeholder=" "
                    defaultValue={defaultValue}
                    disabled={disabled}
                />
                <label
                    htmlFor={field}
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                    {label}
                </label>
            </div>
            <ErrorTextWithIcon text={errors?.[field]?.message} />
        </div>
    );
};

export default PromoFloatingLabelInputField;
