import React, { useEffect, useRef, useState } from "react";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { Controller } from "react-hook-form";
import {
    GOOGLE_PLACE_FORMATED_ADDRESS,
    UNDEFINED,
    NAME_FIELD,
    GEOMETRY_FIELD,
    ADDRESS_FIELD,
    PLACE_CHANGED,
    ENTER_KEY,
    AE,
    SA,
} from "./constants";

const FloatingAddressAutoCompleteField = ({
    field,
    errors,
    setError,
    setValue,
    label,
    control,
    defaultValue,
    searchCountry,
    setAutCompleteAddress = () => {},
}) => {
    let autoComplete;

    const autoCompleteRef = useRef(null);

    const handleAutoComplete = () => {
        try {
            const place = autoComplete.getPlace();
            if (place?.address_components) {
                setValue(field, autoCompleteRef.current.value);
                setAutCompleteAddress(GOOGLE_PLACE_FORMATED_ADDRESS(place));
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (typeof window !== UNDEFINED) {
            try {
                autoComplete = new window.google.maps.places.Autocomplete(
                    autoCompleteRef.current,
                    {
                        componentRestrictions: {
                            country: searchCountry?.toLowerCase(),
                        },
                        fields: [ADDRESS_FIELD, GEOMETRY_FIELD, NAME_FIELD],
                    }
                );

                autoComplete.addListener(PLACE_CHANGED, function () {
                    handleAutoComplete();
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [searchCountry]);

    useEffect(() => {
        if (typeof window !== UNDEFINED) {
            autoCompleteRef.current.value = defaultValue;
        }
    }, [defaultValue]);

    return (
        <div className="flex-1">
            <div className={`relative`}>
                <Controller
                    control={control}
                    name={field}
                    rules={{
                        required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                            ref={(e) => {
                                ref(e);
                                autoCompleteRef.current = e;
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            onKeyPress={(e) => {
                                e.key === ENTER_KEY && e.preventDefault();
                            }}
                            className="block px-2.5 pb-2.5 pt-2 w-full text-base text-gray-900 bg-transparent rounded border border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                        />
                    )}
                />
                <label
                    htmlFor={field}
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                    {label}
                </label>
            </div>
            {errors?.[field]?.message && (
                <ErrorTextWithIcon text={errors?.[field]?.message} />
            )}
        </div>
    );
};

export default FloatingAddressAutoCompleteField;
