import React from "react";
import {
    INPUT_FIELD_WITH_BUTTON_CONTAINER,
    INPUT_CONTAINER,
    INPUT_FIELD_WITH_BUTTON_CLASS,
} from "./constants";
import { INPUT_FIELD_ERROR } from "../../../../public/Constants/EnumConstants";
import { InputLabelLight } from "../../Typography/InputLabel";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import InputFieldPrimaryButton from "../../Buttons/InputFieldPrimaryButton/InputFieldPrimaryButton";

const InputFieldWithButton = ({
    buttonText,
    errors,
    label,
    type,
    placeholder,
    field,
    register,
    height = "",
    style = "",
    name,
    onClick,
    value,
    disableField,
}) => {
    const inputClassName = errors?.[field]
        ? INPUT_FIELD_ERROR
        : INPUT_FIELD_WITH_BUTTON_CLASS;
    const errorMessage = name
        ? errors?.[name]?.message
        : errors?.[field]?.message;
    return (
        <>
            <div className={INPUT_CONTAINER}>
                <InputLabelLight label={label} />
                <div className={INPUT_FIELD_WITH_BUTTON_CONTAINER}>
                    <input
                        className={inputClassName + height + style}
                        type={type}
                        placeholder={placeholder}
                        {...register?.(field)}
                        value={value}
                        disabled={disableField}
                    />
                    <InputFieldPrimaryButton
                        text={buttonText}
                        onClick={onClick}
                        className=" !min-w-max"
                    />
                </div>
            </div>
            <InputErrorMessage errorMessage={errorMessage} />
        </>
    );
};

export default InputFieldWithButton;
