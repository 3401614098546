import { getCookie, setCookie } from "cookies-next";

export const countriesWithRate = () => {
    const list = COUNTRIES_WITH_CURRENCY?.map((country) => ({
        ...country,
        subscription_rate: Rates?.find((rate) => country?.code === rate?.code)
            ?.price,
    }));
    return list;
};

export const getCountryDetails = () => {
    if (getCookie("currency") && getCookie("country")) {
        return {
            ...JSON?.parse(getCookie("currency") || '{}'),
            ...JSON?.parse(getCookie("country") || '{}'),
        };
    }
    return null;
};

const Rates = [
    { price: 109, name: "UAE Dirham", code: "AED" },
    { price: 59, name: "Euro", code: "EUR" },
    { price: 109, name: "Saudi Riyal", code: "SAR" },
    { price: 69, name: "US Dollar", code: "USD" },
    { price: 49, name: "British Pound", code: "GBP" },
    { price: 69, name: "Australian Dollar", code: "AUD" },
];

const COUNTRIES_WITH_CURRENCY = [
    {
        country: "Albania",
        main_currency: "ALL",
        code: "USD",
        iso_alpha_2: "AL",
    },
    {
        country: "Algeria",
        main_currency: "DZD",
        code: "USD",
        iso_alpha_2: "DZ",
    },
    {
        country: "Andorra",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "AD",
    },
    { country: "Angola", main_currency: "AOA", code: "USD", iso_alpha_2: "AO" },
    {
        country: "Antigua and Barbuda",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    {
        country: "Argentina",
        main_currency: "ARS",
        code: "USD",
        iso_alpha_2: "AR",
    },
    {
        country: "Armenia",
        main_currency: "AMD",
        code: "USD",
        iso_alpha_2: "AM",
    },
    {
        country: "Australia",
        main_currency: "AUD",
        code: "AUD",
        iso_alpha_2: "AU",
    },
    {
        country: "Austria",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "AT",
    },
    {
        country: "Azerbaijan",
        main_currency: "AZN",
        code: "USD",
        iso_alpha_2: "AZ",
    },
    {
        country: "Bahamas",
        main_currency: "BSD",
        code: "USD",
        iso_alpha_2: "BS",
    },
    {
        country: "Bahrain",
        main_currency: "BHD",
        code: "USD",
        iso_alpha_2: "BH",
    },
    {
        country: "Bangladesh",
        main_currency: "BDT",
        code: "USD",
        iso_alpha_2: "BD",
    },
    {
        country: "Barbados",
        main_currency: "BBD",
        code: "USD",
        iso_alpha_2: "BB",
    },
    {
        country: "Belarus",
        main_currency: "BYN",
        code: "USD",
        iso_alpha_2: "BY",
    },
    {
        country: "Belgium",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "BE",
    },
    { country: "Belize", main_currency: "BZD", code: "USD", iso_alpha_2: "BZ" },
    { country: "Benin", main_currency: "XOF", code: "USD", iso_alpha_2: "BJ" },
    { country: "Bhutan", main_currency: "BTN", code: "USD", iso_alpha_2: "BT" },
    {
        country: "Bolivia",
        main_currency: "BOB",
        code: "USD",
        iso_alpha_2: "BO",
    },
    {
        country: "Bosnia and Herzegovina",
        main_currency: "BAM",
        code: "USD",
        iso_alpha_2: "BA",
    },
    {
        country: "Botswana",
        main_currency: "BWP",
        code: "USD",
        iso_alpha_2: "BW",
    },
    { country: "Brazil", main_currency: "BRL", code: "USD", iso_alpha_2: "BR" },
    { country: "Brunei", main_currency: "BND", code: "USD", iso_alpha_2: "BN" },
    {
        country: "Bulgaria",
        main_currency: "BGN",
        code: "USD",
        iso_alpha_2: "BG",
    },
    {
        country: "Burkina Faso",
        main_currency: "XOF",
        code: "USD",
        iso_alpha_2: "BJ",
    },
    {
        country: "Burundi",
        main_currency: "BIF",
        code: "USD",
        iso_alpha_2: "BI",
    },
    {
        country: "Cabo Verde",
        main_currency: "CVE",
        code: "USD",
        iso_alpha_2: "CV",
    },
    {
        country: "Cambodia",
        main_currency: "KHR",
        code: "USD",
        iso_alpha_2: "KH",
    },
    {
        country: "Cameroon",
        main_currency: "XAF",
        code: "USD",
        iso_alpha_2: "CM",
    },
    { country: "Canada", main_currency: "CAD", code: "USD", iso_alpha_2: "CA" },
    {
        country: "Central African Republic",
        main_currency: "XAF",
        code: "USD",
        iso_alpha_2: "CM",
    },
    { country: "Chad", main_currency: "XAF", code: "USD", iso_alpha_2: "CM" },
    { country: "Chile", main_currency: "CLP", code: "USD", iso_alpha_2: "CL" },
    { country: "China", main_currency: "CNY", code: "USD", iso_alpha_2: "CN" },
    {
        country: "Colombia",
        main_currency: "COP",
        code: "USD",
        iso_alpha_2: "CO",
    },
    {
        country: "Comoros",
        main_currency: "KMF",
        code: "USD",
        iso_alpha_2: "KM",
    },
    {
        country: "Congo (Congo-Brazzaville)",
        main_currency: "XAF",
        code: "USD",
        iso_alpha_2: "CM",
    },
    {
        country: "Costa Rica",
        main_currency: "CRC",
        code: "USD",
        iso_alpha_2: "CR",
    },
    {
        country: "Croatia",
        main_currency: "HRK",
        code: "USD",
        iso_alpha_2: "HR",
    },
    { country: "Cuba", main_currency: "CUP", code: "USD", iso_alpha_2: "CU" },
    { country: "Cyprus", main_currency: "EUR", code: "EUR", iso_alpha_2: "CY" },
    {
        country: "Czech Republic",
        main_currency: "CZK",
        code: "USD",
        iso_alpha_2: "CZ",
    },
    {
        country: "Denmark",
        main_currency: "DKK",
        code: "USD",
        iso_alpha_2: "DK",
    },
    {
        country: "Djibouti",
        main_currency: "DJF",
        code: "USD",
        iso_alpha_2: "DJ",
    },
    {
        country: "Dominica",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    {
        country: "Dominican Republic",
        main_currency: "DOP",
        code: "USD",
        iso_alpha_2: "DO",
    },
    {
        country: "East Timor",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "TL",
    },
    {
        country: "Ecuador",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "TL",
    },
    { country: "Egypt", main_currency: "EGP", code: "USD", iso_alpha_2: "EG" },
    {
        country: "El Salvador",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "TL",
    },
    {
        country: "Equatorial Guinea",
        main_currency: "XAF",
        code: "USD",
        iso_alpha_2: "CM",
    },
    {
        country: "Eritrea",
        main_currency: "ERN",
        code: "USD",
        iso_alpha_2: "ER",
    },
    {
        country: "Estonia",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "EE",
    },
    {
        country: "Eswatini",
        main_currency: "SZL",
        code: "USD",
        iso_alpha_2: "SZ",
    },
    {
        country: "Ethiopia",
        main_currency: "ETB",
        code: "USD",
        iso_alpha_2: "ET",
    },
    { country: "Fiji", main_currency: "FJD", code: "USD", iso_alpha_2: "FJ" },
    {
        country: "Finland",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "FI",
    },
    { country: "France", main_currency: "EUR", code: "EUR", iso_alpha_2: "FR" },
    { country: "Gabon", main_currency: "XAF", code: "USD", iso_alpha_2: "CM" },
    { country: "Gambia", main_currency: "GMD", code: "USD", iso_alpha_2: "GM" },
    {
        country: "Georgia",
        main_currency: "GEL",
        code: "USD",
        iso_alpha_2: "GE",
    },
    {
        country: "Germany",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "DE",
    },
    { country: "Ghana", main_currency: "GHS", code: "USD", iso_alpha_2: "GH" },
    { country: "Greece", main_currency: "EUR", code: "EUR", iso_alpha_2: "GR" },
    {
        country: "Grenada",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    {
        country: "Guatemala",
        main_currency: "GTQ",
        code: "USD",
        iso_alpha_2: "GT",
    },
    { country: "Guinea", main_currency: "GNF", code: "USD", iso_alpha_2: "GN" },
    {
        country: "Guinea-Bissau",
        main_currency: "XOF",
        code: "USD",
        iso_alpha_2: "BJ",
    },
    { country: "Guyana", main_currency: "GYD", code: "USD", iso_alpha_2: "GY" },
    { country: "Haiti", main_currency: "HTG", code: "USD", iso_alpha_2: "HT" },
    {
        country: "Honduras",
        main_currency: "HNL",
        code: "USD",
        iso_alpha_2: "HN",
    },
    {
        country: "Hungary",
        main_currency: "HUF",
        code: "USD",
        iso_alpha_2: "HU",
    },
    {
        country: "Iceland",
        main_currency: "ISK",
        code: "USD",
        iso_alpha_2: "IS",
    },
    { country: "India", main_currency: "INR", code: "USD", iso_alpha_2: "IN" },
    {
        country: "Indonesia",
        main_currency: "IDR",
        code: "USD",
        iso_alpha_2: "ID",
    },
    { country: "Iran", main_currency: "IRR", code: "USD", iso_alpha_2: "IR" },
    { country: "Iraq", main_currency: "IQD", code: "USD", iso_alpha_2: "IQ" },
    {
        country: "Ireland",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "IE",
    },
    { country: "Israel", main_currency: "ILS", code: "USD", iso_alpha_2: "IL" },
    { country: "Italy", main_currency: "EUR", code: "EUR", iso_alpha_2: "IT" },
    {
        country: "Ivory Coast",
        main_currency: "XOF",
        code: "USD",
        iso_alpha_2: "BJ",
    },
    {
        country: "Jamaica",
        main_currency: "JMD",
        code: "USD",
        iso_alpha_2: "JM",
    },
    { country: "Japan", main_currency: "JPY", code: "USD", iso_alpha_2: "JP" },
    { country: "Jordan", main_currency: "JOD", code: "USD", iso_alpha_2: "JO" },
    {
        country: "Kazakhstan",
        main_currency: "KZT",
        code: "USD",
        iso_alpha_2: "KZ",
    },
    { country: "Kenya", main_currency: "KES", code: "USD", iso_alpha_2: "KE" },
    {
        country: "Kiribati",
        main_currency: "AUD",
        code: "AUD",
        iso_alpha_2: "KI",
    },
    { country: "Kuwait", main_currency: "KWD", code: "USD", iso_alpha_2: "KW" },
    {
        country: "Kyrgyzstan",
        main_currency: "KGS",
        code: "USD",
        iso_alpha_2: "KG",
    },
    { country: "Laos", main_currency: "LAK", code: "USD", iso_alpha_2: "LA" },
    { country: "Latvia", main_currency: "EUR", code: "EUR", iso_alpha_2: "LV" },
    {
        country: "Lebanon",
        main_currency: "LBP",
        code: "USD",
        iso_alpha_2: "LB",
    },
    {
        country: "Lesotho",
        main_currency: "LSL",
        code: "USD",
        iso_alpha_2: "LS",
    },
    {
        country: "Liberia",
        main_currency: "LRD",
        code: "USD",
        iso_alpha_2: "LR",
    },
    { country: "Libya", main_currency: "LYD", code: "USD", iso_alpha_2: "LY" },
    {
        country: "Liechtenstein",
        main_currency: "CHF",
        code: "USD",
        iso_alpha_2: "LI",
    },
    {
        country: "Lithuania",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "LT",
    },
    {
        country: "Luxembourg",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "LU",
    },
    {
        country: "Madagascar",
        main_currency: "MGA",
        code: "USD",
        iso_alpha_2: "MG",
    },
    { country: "Malawi", main_currency: "MWK", code: "USD", iso_alpha_2: "MW" },
    {
        country: "Malaysia",
        main_currency: "MYR",
        code: "USD",
        iso_alpha_2: "MY",
    },
    {
        country: "Maldives",
        main_currency: "MVR",
        code: "USD",
        iso_alpha_2: "MV",
    },
    { country: "Mali", main_currency: "XOF", code: "USD", iso_alpha_2: "BJ" },
    { country: "Malta", main_currency: "EUR", code: "EUR", iso_alpha_2: "MT" },
    {
        country: "Marshall Islands",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "TL",
    },
    {
        country: "Mauritania",
        main_currency: "MRU",
        code: "USD",
        iso_alpha_2: "MR",
    },
    {
        country: "Mauritius",
        main_currency: "MUR",
        code: "USD",
        iso_alpha_2: "MU",
    },
    { country: "Mexico", main_currency: "MXN", code: "USD", iso_alpha_2: "MX" },
    {
        country: "Micronesia",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "TL",
    },
    {
        country: "Moldova",
        main_currency: "MDL",
        code: "USD",
        iso_alpha_2: "MD",
    },
    { country: "Monaco", main_currency: "EUR", code: "EUR", iso_alpha_2: "MC" },
    {
        country: "Mongolia",
        main_currency: "MNT",
        code: "USD",
        iso_alpha_2: "MN",
    },
    {
        country: "Montenegro",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "ME",
    },
    {
        country: "Morocco",
        main_currency: "MAD",
        code: "USD",
        iso_alpha_2: "MA",
    },
    {
        country: "Mozambique",
        main_currency: "MZN",
        code: "USD",
        iso_alpha_2: "MZ",
    },
    {
        country: "Myanmar",
        main_currency: "MMK",
        code: "USD",
        iso_alpha_2: undefined,
    },
    {
        country: "Namibia",
        main_currency: "NAD",
        code: "USD",
        iso_alpha_2: "NA",
    },
    { country: "Nauru", main_currency: "AUD", code: "AUD", iso_alpha_2: "NR" },
    { country: "Nepal", main_currency: "NPR", code: "USD", iso_alpha_2: "NP" },
    {
        country: "Netherlands",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "NL",
    },
    {
        country: "New Zealand",
        main_currency: "NZD",
        code: "USD",
        iso_alpha_2: "NZ",
    },
    {
        country: "Nicaragua",
        main_currency: "NIO",
        code: "USD",
        iso_alpha_2: "NI",
    },
    { country: "Niger", main_currency: "XOF", code: "USD", iso_alpha_2: "BJ" },
    {
        country: "Nigeria",
        main_currency: "NGN",
        code: "USD",
        iso_alpha_2: "NG",
    },
    {
        country: "North Macedonia",
        main_currency: "MKD",
        code: "USD",
        iso_alpha_2: "MK",
    },
    { country: "Norway", main_currency: "NOK", code: "USD", iso_alpha_2: "NO" },
    { country: "Oman", main_currency: "OMR", code: "USD", iso_alpha_2: "OM" },
    {
        country: "Pakistan",
        main_currency: "PKR",
        code: "USD",
        iso_alpha_2: "PK",
    },
    { country: "Palau", main_currency: "USD", code: "USD", iso_alpha_2: "TL" },
    { country: "Panama", main_currency: "PAB", code: "USD", iso_alpha_2: "PA" },
    {
        country: "Papua New Guinea",
        main_currency: "PGK",
        code: "USD",
        iso_alpha_2: "PG",
    },
    {
        country: "Paraguay",
        main_currency: "PYG",
        code: "USD",
        iso_alpha_2: "PY",
    },
    { country: "Peru", main_currency: "PEN", code: "USD", iso_alpha_2: "PE" },
    {
        country: "Philippines",
        main_currency: "PHP",
        code: "USD",
        iso_alpha_2: "PH",
    },
    { country: "Poland", main_currency: "PLN", code: "USD", iso_alpha_2: "PL" },
    {
        country: "Portugal",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "PT",
    },
    {
        country: "Romania",
        main_currency: "RON",
        code: "USD",
        iso_alpha_2: "RO",
    },
    { country: "Russia", main_currency: "RUB", code: "USD", iso_alpha_2: "RU" },
    { country: "Rwanda", main_currency: "RWF", code: "USD", iso_alpha_2: "RW" },
    {
        country: "Saint Kitts and Nevis",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    {
        country: "Saint Lucia",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    {
        country: "Saint Vincent and the Grenadines",
        main_currency: "XCD",
        code: "USD",
        iso_alpha_2: "AG",
    },
    { country: "Samoa", main_currency: "WST", code: "USD", iso_alpha_2: "WS" },
    {
        country: "San Marino",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "SM",
    },
    {
        country: "São Tomé and Príncipe",
        main_currency: "STN",
        code: "USD",
        iso_alpha_2: "ST",
    },
    {
        country: "Saudi Arabia",
        main_currency: "SAR",
        code: "SAR",
        iso_alpha_2: "SA",
    },
    {
        country: "Senegal",
        main_currency: "XOF",
        code: "USD",
        iso_alpha_2: "BJ",
    },
    { country: "Serbia", main_currency: "RSD", code: "USD", iso_alpha_2: "RS" },
    {
        country: "Seychelles",
        main_currency: "SCR",
        code: "USD",
        iso_alpha_2: "SC",
    },
    {
        country: "Sierra Leone",
        main_currency: "SLL",
        code: "USD",
        iso_alpha_2: undefined,
    },
    {
        country: "Singapore",
        main_currency: "SGD",
        code: "USD",
        iso_alpha_2: "SG",
    },
    {
        country: "Slovakia",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "SK",
    },
    {
        country: "Slovenia",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "SI",
    },
    {
        country: "Solomon Islands",
        main_currency: "SBD",
        code: "USD",
        iso_alpha_2: "SB",
    },
    {
        country: "Somalia",
        main_currency: "SOS",
        code: "USD",
        iso_alpha_2: "SO",
    },
    {
        country: "South Africa",
        main_currency: "ZAR",
        code: "USD",
        iso_alpha_2: "ZA",
    },
    {
        country: "South Korea",
        main_currency: "KRW",
        code: "USD",
        iso_alpha_2: "KR",
    },
    {
        country: "South Sudan",
        main_currency: "SSP",
        code: "USD",
        iso_alpha_2: "SS",
    },
    { country: "Spain", main_currency: "EUR", code: "EUR", iso_alpha_2: "ES" },
    {
        country: "Sri Lanka",
        main_currency: "LKR",
        code: "USD",
        iso_alpha_2: "LK",
    },
    { country: "Sudan", main_currency: "SDG", code: "USD", iso_alpha_2: "SD" },
    {
        country: "Suriname",
        main_currency: "SRD",
        code: "USD",
        iso_alpha_2: "SR",
    },
    { country: "Sweden", main_currency: "SEK", code: "USD", iso_alpha_2: "SE" },
    {
        country: "Switzerland",
        main_currency: "CHF",
        code: "USD",
        iso_alpha_2: "LI",
    },
    { country: "Taiwan", main_currency: "TWD", code: "USD", iso_alpha_2: "TW" },
    {
        country: "Tajikistan",
        main_currency: "TJS",
        code: "USD",
        iso_alpha_2: "TJ",
    },
    {
        country: "Tanzania",
        main_currency: "TZS",
        code: "USD",
        iso_alpha_2: "TZ",
    },
    {
        country: "Thailand",
        main_currency: "THB",
        code: "USD",
        iso_alpha_2: "TH",
    },
    { country: "Togo", main_currency: "XOF", code: "USD", iso_alpha_2: "BJ" },
    { country: "Tonga", main_currency: "TOP", code: "USD", iso_alpha_2: "TO" },
    {
        country: "Trinidad and Tobago",
        main_currency: "TTD",
        code: "USD",
        iso_alpha_2: "TT",
    },
    {
        country: "Tunisia",
        main_currency: "TND",
        code: "USD",
        iso_alpha_2: "TN",
    },
    { country: "Turkey", main_currency: "TRY", code: "USD", iso_alpha_2: "TR" },
    {
        country: "Turkmenistan",
        main_currency: "TMT",
        code: "USD",
        iso_alpha_2: "TM",
    },
    { country: "Tuvalu", main_currency: "AUD", code: "AUD", iso_alpha_2: "AU" },
    { country: "Uganda", main_currency: "UGX", code: "USD", iso_alpha_2: "UG" },
    {
        country: "Ukraine",
        main_currency: "UAH",
        code: "USD",
        iso_alpha_2: "UA",
    },
    {
        country: "United Arab Emirates",
        main_currency: "AED",
        code: "AED",
        iso_alpha_2: "AE",
    },
    {
        country: "United Kingdom",
        main_currency: "GBP",
        code: "GBP",
        iso_alpha_2: "GB",
    },
    {
        country: "United States",
        main_currency: "USD",
        code: "USD",
        iso_alpha_2: "US",
    },
    {
        country: "Uruguay",
        main_currency: "UYU",
        code: "USD",
        iso_alpha_2: "UY",
    },
    {
        country: "Uzbekistan",
        main_currency: "UZS",
        code: "USD",
        iso_alpha_2: "UZ",
    },
    {
        country: "Vanuatu",
        main_currency: "VUV",
        code: "USD",
        iso_alpha_2: "VU",
    },
    {
        country: "Vatican City",
        main_currency: "EUR",
        code: "EUR",
        iso_alpha_2: "VA",
    },
    {
        country: "Venezuela",
        main_currency: "VES",
        code: "USD",
        iso_alpha_2: "VE",
    },
    {
        country: "Vietnam",
        main_currency: "VND",
        code: "USD",
        iso_alpha_2: "VN",
    },
    { country: "Zambia", main_currency: "ZMW", code: "USD", iso_alpha_2: "ZM" },
    {
        country: "Zimbabwe",
        main_currency: "ZWL",
        code: "USD",
        iso_alpha_2: "ZW",
    },
];
