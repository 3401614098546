"use client";

import React from "react";
import {
    PRIMARY_BUTTON_CLASS,
    PRIMARY_BUTTON_DISABLED_CLASS,
} from "./constants";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";

const InputFieldPrimaryButton = ({
    text,
    onClick,
    processing,
    width,
    href,
    className,
    style,
    type,
    disabled,
}) => {
    const buttonClass = disabled
        ? PRIMARY_BUTTON_DISABLED_CLASS
        : PRIMARY_BUTTON_CLASS;
    return href ? (
        <Link
            href={href}
            passHref
            prefetch
            className={width + PRIMARY_BUTTON_CLASS + className}
            style={style}
            onClick={onClick}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt="processing gif"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                />
            ) : (
                text
            )}
        </Link>
    ) : (
        <button
            type={type}
            className={width + buttonClass + className}
            style={style}
            onClick={onClick}
            disabled={disabled}
        >
            {processing ? (
                <Image
                    src={LoadingWhiteSpinner}
                    width={31}
                    height={30}
                    alt="jje"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                />
            ) : (
                text
            )}
        </button>
    );
};

export default InputFieldPrimaryButton;
