import React from "react";
import Image from "next/image";
import { WearThatLogo } from "../../../public/Theme";
import { CONTAINER_STYLE, LOGO_STYLE } from "./styles";

const ProgressLoader = () => {
    return (
        <div className={CONTAINER_STYLE}>
            <Image
                width={100}
                height={100}
                src={WearThatLogo}
                className={LOGO_STYLE}
                alt="wearthat loader"
            />
        </div>
    );
};

export default ProgressLoader;
